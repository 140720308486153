import { defineAsyncComponent } from 'vue'
export default {
  account: defineAsyncComponent(() => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/layouts/account.vue").then(m => m.default || m)),
  "admin-with-sidebar-menu": defineAsyncComponent(() => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/layouts/admin-with-sidebar-menu.vue").then(m => m.default || m)),
  "default-without-header": defineAsyncComponent(() => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/layouts/default-without-header.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/layouts/default.vue").then(m => m.default || m)),
  empty: defineAsyncComponent(() => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/layouts/empty.vue").then(m => m.default || m)),
  "form-mission": defineAsyncComponent(() => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/layouts/form-mission.vue").then(m => m.default || m)),
  "messages-params": defineAsyncComponent(() => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/layouts/messages-params.vue").then(m => m.default || m)),
  messages: defineAsyncComponent(() => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/layouts/messages.vue").then(m => m.default || m)),
  quiz: defineAsyncComponent(() => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/layouts/quiz.vue").then(m => m.default || m)),
  "register-steps": defineAsyncComponent(() => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/layouts/register-steps.vue").then(m => m.default || m)),
  "statistics-admin": defineAsyncComponent(() => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/layouts/statistics-admin.vue").then(m => m.default || m)),
  "statistics-public": defineAsyncComponent(() => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/layouts/statistics-public.vue").then(m => m.default || m)),
  support: defineAsyncComponent(() => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/layouts/support.vue").then(m => m.default || m)),
  temoignage: defineAsyncComponent(() => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/layouts/temoignage.vue").then(m => m.default || m))
}