import validate from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/middleware/maintenance.global.ts";
import manifest_45route_45rule from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  maintenance_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/middleware/admin.ts"),
  "agreed-responsable-terms": () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/middleware/agreed-responsable-terms.ts"),
  authenticated: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/middleware/authenticated.ts"),
  guest: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/middleware/guest.ts"),
  "old-search-redirections": () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/middleware/old-search-redirections.ts")
}