
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as accessibilite9aVCGxsBpjMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/accessibilite.vue?macro=true";
import { default as _91slug_93unpvzzWp29Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/activites/[slug].vue?macro=true";
import { default as editDiSvFZSaKVMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/activites/[id]/edit.vue?macro=true";
import { default as indexmpN6s6wNKGMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/activites/[id]/index.vue?macro=true";
import { default as addAzkuVNVtogMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/activites/add.vue?macro=true";
import { default as indextwi0f3fxjyMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/activites/index.vue?macro=true";
import { default as editYEenP8jgMFMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/domaines/[id]/edit.vue?macro=true";
import { default as addOucQnfggLbMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/domaines/add.vue?macro=true";
import { default as indexO1aNoVOopEMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/domaines/index.vue?macro=true";
import { default as editPoS6qB5NwDMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/modeles-mission/[id]/edit.vue?macro=true";
import { default as index2IEJb1jdMGMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/modeles-mission/[id]/index.vue?macro=true";
import { default as addoEesxztQi9Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/modeles-mission/add.vue?macro=true";
import { default as index8RRfN8WXvXMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/modeles-mission/index.vue?macro=true";
import { default as postsN4lAvFv5yxMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/posts.vue?macro=true";
import { default as editlw6zUSqRlAMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/reseaux/[id]/edit.vue?macro=true";
import { default as indexhRqvzxKDscMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/reseaux/[id]/index.vue?macro=true";
import { default as addRYkPCIbI4DMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/reseaux/add.vue?macro=true";
import { default as indexu5dD7XMIeiMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/reseaux/index.vue?macro=true";
import { default as editPordfubt4gMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/ressources/[id]/edit.vue?macro=true";
import { default as addI0XzGkguynMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/ressources/add.vue?macro=true";
import { default as indexS7YQtLnLhbMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/ressources/index.vue?macro=true";
import { default as editbjrdQz2dVmMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/territoires/[id]/edit.vue?macro=true";
import { default as indexbGmCLmwKslMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/territoires/[id]/index.vue?macro=true";
import { default as addvl7JLAPIVQMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/territoires/add.vue?macro=true";
import { default as indexDw9yMt5XZUMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/territoires/index.vue?macro=true";
import { default as editKkOip6fG15Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/testimonials/[id]/edit.vue?macro=true";
import { default as indexERUVgJq9DWMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/testimonials/index.vue?macro=true";
import { default as indexEWl7ATXQySMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/index.vue?macro=true";
import { default as benevoles_45informationsUgnEQVVK4IMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/benevoles-informations.vue?macro=true";
import { default as benevolesBwAsdfh5quMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/benevoles.vue?macro=true";
import { default as dates8WvGOX9rCmMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/dates.vue?macro=true";
import { default as indexZiKc9p2uY6Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/index.vue?macro=true";
import { default as informationsnV7Qh10QyIMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/informations.vue?macro=true";
import { default as lieuxKkCIWrQLOsMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/lieux.vue?macro=true";
import { default as responsablesiViv479Du9Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/responsables.vue?macro=true";
import { default as titleOV8ZIKvr9zMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/title.vue?macro=true";
import { default as index2QqKjiBHaJMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/trouver-des-benevoles/index.vue?macro=true";
import { default as redirectLV0MjAAbdUMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/trouver-des-benevoles/redirect.vue?macro=true";
import { default as visuelisOzRlHlzpMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/visuel.vue?macro=true";
import { default as indexGIo22J4V3LMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/index.vue?macro=true";
import { default as notesaR9Q1S0bmFMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/notes.vue?macro=true";
import { default as editDhPSYgudvyMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/organisations/[id]/edit.vue?macro=true";
import { default as indexLmGFAvL9uEMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/organisations/[id]/index.vue?macro=true";
import { default as addeVqQ2PRFfeMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/organisations/[id]/missions/add.vue?macro=true";
import { default as indexFoWR65wf0PMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/organisations/index.vue?macro=true";
import { default as invitations7xvzKUZH1pMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/other/invitations.vue?macro=true";
import { default as logsCS4gFFWZIoMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/other/logs.vue?macro=true";
import { default as indexuPcNW20bPuMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/participations/index.vue?macro=true";
import { default as ressourcesPMeO9BZTPbMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/ressources.vue?macro=true";
import { default as emailsSmSratLmIKMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/settings/emails.vue?macro=true";
import { default as messagesykyFwupoCcMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/settings/messages.vue?macro=true";
import { default as editJJv655XPEWMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/settings/rules/[id]/edit.vue?macro=true";
import { default as pending_45itemsDAFsJ0BoatMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/settings/rules/[id]/pending-items.vue?macro=true";
import { default as addbDS3HBnOXEMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/settings/rules/add.vue?macro=true";
import { default as indexIzOll97JAIMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/settings/rules/index.vue?macro=true";
import { default as trafic_45entranthdar7huWmDMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/api-engagement/trafic-entrant.vue?macro=true";
import { default as trafic_45sortantGjjHgtMiCMMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/api-engagement/trafic-sortant.vue?macro=true";
import { default as conversionsScDY649YcJMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/conversions.vue?macro=true";
import { default as missions_45a_45validervRToswqNPdMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/departements/missions-a-valider.vue?macro=true";
import { default as missions_45perimeeskDSIC6w8eFMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/departements/missions-perimees.vue?macro=true";
import { default as organisations_45a_45validerb3txtskp7VMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/departements/organisations-a-valider.vue?macro=true";
import { default as indexuGrApDhxVSMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/index.vue?macro=true";
import { default as indicateurs_45clesvxKAYTOaqlMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/indicateurs-cles.vue?macro=true";
import { default as missionsQ6CtSxxikzMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/missions.vue?macro=true";
import { default as indexAxBOtKYUbyMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/organisations/index.vue?macro=true";
import { default as missions_45perimeesYMg0LBdldbMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/organisations/missions-perimees.vue?macro=true";
import { default as participations_45a_45validero6vnKhczlJMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/organisations/participations-a-valider.vue?macro=true";
import { default as participations_45refusees_45annuleesFL9iLwlyGzMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/organisations/participations-refusees-annulees.vue?macro=true";
import { default as participationsD9tu6t1xtAMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/participations.vue?macro=true";
import { default as placesiMNxEqYXGvMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/places.vue?macro=true";
import { default as moderationsu9yEBSHg05Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/suivis/moderations.vue?macro=true";
import { default as utilisateursrk79P4nPF0Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/utilisateurs.vue?macro=true";
import { default as editPjoELgkUueMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/taxonomies/[slug]/[id]/edit.vue?macro=true";
import { default as addxly1KgIDqPMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/taxonomies/[slug]/add.vue?macro=true";
import { default as indexYuhKsIGPyIMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/taxonomies/[slug]/index.vue?macro=true";
import { default as temoignagesn3BXJiglsjMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/temoignages.vue?macro=true";
import { default as editsbv44cRjCfMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/utilisateurs/[id]/edit.vue?macro=true";
import { default as indexDPAc89APNOMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/utilisateurs/[id]/index.vue?macro=true";
import { default as indexYlaSUQxk1kMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/utilisateurs/index.vue?macro=true";
import { default as _91slug_93ix9M1LRDDxMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/associations/[slug].vue?macro=true";
import { default as benevolesLi97wtzexvMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/charte-bon-fonctionnement/benevoles.vue?macro=true";
import { default as indexJ6sTqrQXLtMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/charte-bon-fonctionnement/index.vue?macro=true";
import { default as responsables7QNalH5EBuMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/charte-bon-fonctionnement/responsables.vue?macro=true";
import { default as charte_45reserve_45civiquetxV7DIslP2Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/charte-reserve-civique.vue?macro=true";
import { default as conditions_45generales_45d_45utilisationj8br0Ef9HGMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/conditions-generales-d-utilisation.vue?macro=true";
import { default as indexIMtSY72J3wMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/dashboard/index.vue?macro=true";
import { default as _91slug_93WIf9o8btROMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/departements/[slug].vue?macro=true";
import { default as _91slug_93jpY4tfn2H2Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/domaines-action/[slug].vue?macro=true";
import { default as en_45ce_45momentMzWmRoInrvMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/en-ce-moment.vue?macro=true";
import { default as indexEnW2f8460sMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/index.vue?macro=true";
import { default as indexN3mDyPsBisMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/benevole/index.vue?macro=true";
import { default as competences4h7wpUbIoMMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/benevole/step/competences.vue?macro=true";
import { default as disponibilites5yza8guvPYMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/benevole/step/disponibilites.vue?macro=true";
import { default as preferencesnSxXkqwWLIMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/benevole/step/preferences.vue?macro=true";
import { default as profileZvm4nmgwkDMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/benevole/step/profile.vue?macro=true";
import { default as indexo64d6GB4saMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/index.vue?macro=true";
import { default as indexkVxkz8uIxDMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/index.vue?macro=true";
import { default as charte_45bon_45fonctionnementjDtUI0dfspMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/charte-bon-fonctionnement.vue?macro=true";
import { default as collectivite_45confirmationPYMrtAYCAWMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/collectivite-confirmation.vue?macro=true";
import { default as collectivitezvA8W6H3mbMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/collectivite.vue?macro=true";
import { default as organisation_45confirmationG6qF7xUw48Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/organisation-confirmation.vue?macro=true";
import { default as organisation_45detailsnMvv6ytqgEMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/organisation-details.vue?macro=true";
import { default as organisation_45imagesGBrbIxd3JiMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/organisation-images.vue?macro=true";
import { default as organisationCFQZ6Oo131Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/organisation.vue?macro=true";
import { default as profileDzk4cJ0BLHMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/profile.vue?macro=true";
import { default as _91hash_93EjI9harxopMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/invitations/[hash].vue?macro=true";
import { default as loginpxmnqII6DwMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/login.vue?macro=true";
import { default as admind76e858gCzMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/maintenance/admin.vue?macro=true";
import { default as indexDjUmk8yzTVMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/maintenance/index.vue?macro=true";
import { default as mentions_45legalesjq1Fv1evvvMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/mentions-legales.vue?macro=true";
import { default as _91id_93p2VuKIojliMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/messages/[id].vue?macro=true";
import { default as indexfKI3T5ZIXwMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/messages/index.vue?macro=true";
import { default as addL3IuLY2rjXMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/messages/modeles/add.vue?macro=true";
import { default as indexTUwtqcWm90Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/messages/modeles/index.vue?macro=true";
import { default as _91slug_936oj2pZQxIeMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/missions-benevolat/[id]/[slug].vue?macro=true";
import { default as indexnaQjBet9xrMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/missions-benevolat/[id]/index.vue?macro=true";
import { default as indexAO3SgwahEoMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/missions-benevolat/index.vue?macro=true";
import { default as notificationsbBMxqIWPIJMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/notifications.vue?macro=true";
import { default as _91slug_93VxrzEIaCCoMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/organisations/[slug].vue?macro=true";
import { default as indexBnYcuvJcTyMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/organisations/index.vue?macro=true";
import { default as _91token_93OFZxaBgZkoMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/password-reset/[token].vue?macro=true";
import { default as indexPx6sP7tvyvMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/password-reset/index.vue?macro=true";
import { default as plan_45du_45siteH6pV18hKltMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/plan-du-site.vue?macro=true";
import { default as politique_45de_45confidentialitemPIjoMhxxsMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/politique-de-confidentialite.vue?macro=true";
import { default as indexew5GPrkiOiMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/alertes/index.vue?macro=true";
import { default as charte_45bon_45fonctionnementdzkVesU4aHMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/charte-bon-fonctionnement.vue?macro=true";
import { default as editwW4rWugUuiMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/edit.vue?macro=true";
import { default as favorisHicWBeMChdMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/favoris.vue?macro=true";
import { default as indexNtPjwXFb4MMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/index.vue?macro=true";
import { default as missionsHb9EBPyFMsMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/missions.vue?macro=true";
import { default as notificationsHq5f7IOU89Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/notifications.vue?macro=true";
import { default as preferencesZucYNaD9RcMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/preferences.vue?macro=true";
import { default as settingsmiixXfZZG7Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/settings.vue?macro=true";
import { default as decembre_45ensembleAT1Lt8hBOWMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/quiz/decembre-ensemble.vue?macro=true";
import { default as generiquedOTQ1e4fOkMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/quiz/generique.vue?macro=true";
import { default as mgeneSJV4TrLV1Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/quiz/mgen.vue?macro=true";
import { default as printemps_45pour_45la_45planeteQj7QBfQwaWMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/quiz/printemps-pour-la-planete.vue?macro=true";
import { default as septembre_45pour_45apprendrePJHN0GCtoFMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/quiz/septembre-pour-apprendre.vue?macro=true";
import { default as _91slug_93n15igY616PMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/reseaux/[slug].vue?macro=true";
import { default as indexmV4ftfla9LMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/stats/index.vue?macro=true";
import { default as missionsweQN0xpxmgMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/stats/missions.vue?macro=true";
import { default as organisationsHCb55s7gtnMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/stats/organisations.vue?macro=true";
import { default as participationsCpvWAfttTuMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/stats/participations.vue?macro=true";
import { default as placesviN1FhCocWMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/stats/places.vue?macro=true";
import { default as utilisateursvB6cXkZZdNMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/stats/utilisateurs.vue?macro=true";
import { default as google_45api_45indexingnPQO7ICEO3Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/actions/google-api-indexing.vue?macro=true";
import { default as search_45archived_45usersPtPLHtHvdMMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/actions/search-archived-users.vue?macro=true";
import { default as transfert_45organisationuiqPrdQnI4Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/actions/transfert-organisation.vue?macro=true";
import { default as user_45reset_45context_45roleCNdQIyzcd1Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/actions/user-reset-context-role.vue?macro=true";
import { default as user_45reset_45password_45linkn9zGPRJ6vAMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/actions/user-reset-password-link.vue?macro=true";
import { default as doublons_45organisationstqBZLfg5SOMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/contents/doublons-organisations.vue?macro=true";
import { default as doublons_45territoiresXSkk3urCdrMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/contents/doublons-territoires.vue?macro=true";
import { default as indexZDvtrxE9QXMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/index.vue?macro=true";
import { default as activity_45logszQqpsnj6xpMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/referents/activity-logs.vue?macro=true";
import { default as waiting_45actionsKrANWlVU85Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/referents/waiting-actions.vue?macro=true";
import { default as missions_45outdatedjB1LeLDSuLMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/responsables/missions-outdated.vue?macro=true";
import { default as participations_45to_45be_45treatedrxd1Zf1sDWMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/responsables/participations-to-be-treated.vue?macro=true";
import { default as _91token_93lTLQVodBbXMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/temoignages/[token].vue?macro=true";
import { default as indexMtPS5dSCbZMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/territoires/index.vue?macro=true";
import { default as _91slug_93DsLZqo3SWVMeta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/villes/[slug].vue?macro=true";
import { default as component_45stubBRLwxpG2n1Meta } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubBRLwxpG2n1 } from "/build/bebecc19-04f0-4af6-a037-1fca09232c88/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "accessibilite",
    path: "/accessibilite",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/accessibilite.vue")
  },
  {
    name: "activites-slug",
    path: "/activites/:slug()",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/activites/[slug].vue")
  },
  {
    name: "admin-contenus-activites-id-edit",
    path: "/admin/contenus/activites/:id()/edit",
    meta: editDiSvFZSaKVMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/activites/[id]/edit.vue")
  },
  {
    name: "admin-contenus-activites-id",
    path: "/admin/contenus/activites/:id()",
    meta: indexmpN6s6wNKGMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/activites/[id]/index.vue")
  },
  {
    name: "admin-contenus-activites-add",
    path: "/admin/contenus/activites/add",
    meta: addAzkuVNVtogMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/activites/add.vue")
  },
  {
    name: "admin-contenus-activites",
    path: "/admin/contenus/activites",
    meta: indextwi0f3fxjyMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/activites/index.vue")
  },
  {
    name: "admin-contenus-domaines-id-edit",
    path: "/admin/contenus/domaines/:id()/edit",
    meta: editYEenP8jgMFMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/domaines/[id]/edit.vue")
  },
  {
    name: "admin-contenus-domaines-add",
    path: "/admin/contenus/domaines/add",
    meta: addOucQnfggLbMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/domaines/add.vue")
  },
  {
    name: "admin-contenus-domaines",
    path: "/admin/contenus/domaines",
    meta: indexO1aNoVOopEMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/domaines/index.vue")
  },
  {
    name: "admin-contenus-modeles-mission-id-edit",
    path: "/admin/contenus/modeles-mission/:id()/edit",
    meta: editPoS6qB5NwDMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/modeles-mission/[id]/edit.vue")
  },
  {
    name: "admin-contenus-modeles-mission-id",
    path: "/admin/contenus/modeles-mission/:id()",
    meta: index2IEJb1jdMGMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/modeles-mission/[id]/index.vue")
  },
  {
    name: "admin-contenus-modeles-mission-add",
    path: "/admin/contenus/modeles-mission/add",
    meta: addoEesxztQi9Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/modeles-mission/add.vue")
  },
  {
    name: "admin-contenus-modeles-mission",
    path: "/admin/contenus/modeles-mission",
    meta: index8RRfN8WXvXMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/modeles-mission/index.vue")
  },
  {
    name: "admin-contenus-posts",
    path: "/admin/contenus/posts",
    meta: postsN4lAvFv5yxMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/posts.vue")
  },
  {
    name: "admin-contenus-reseaux-id-edit",
    path: "/admin/contenus/reseaux/:id()/edit",
    meta: editlw6zUSqRlAMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/reseaux/[id]/edit.vue")
  },
  {
    name: "admin-contenus-reseaux-id",
    path: "/admin/contenus/reseaux/:id()",
    meta: indexhRqvzxKDscMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/reseaux/[id]/index.vue")
  },
  {
    name: "admin-contenus-reseaux-add",
    path: "/admin/contenus/reseaux/add",
    meta: addRYkPCIbI4DMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/reseaux/add.vue")
  },
  {
    name: "admin-contenus-reseaux",
    path: "/admin/contenus/reseaux",
    meta: indexu5dD7XMIeiMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/reseaux/index.vue")
  },
  {
    name: "admin-contenus-ressources-id-edit",
    path: "/admin/contenus/ressources/:id()/edit",
    meta: editPordfubt4gMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/ressources/[id]/edit.vue")
  },
  {
    name: "admin-contenus-ressources-add",
    path: "/admin/contenus/ressources/add",
    meta: addI0XzGkguynMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/ressources/add.vue")
  },
  {
    name: "admin-contenus-ressources",
    path: "/admin/contenus/ressources",
    meta: indexS7YQtLnLhbMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/ressources/index.vue")
  },
  {
    name: "admin-contenus-territoires-id-edit",
    path: "/admin/contenus/territoires/:id()/edit",
    meta: editbjrdQz2dVmMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/territoires/[id]/edit.vue")
  },
  {
    name: "admin-contenus-territoires-id",
    path: "/admin/contenus/territoires/:id()",
    meta: indexbGmCLmwKslMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/territoires/[id]/index.vue")
  },
  {
    name: "admin-contenus-territoires-add",
    path: "/admin/contenus/territoires/add",
    meta: addvl7JLAPIVQMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/territoires/add.vue")
  },
  {
    name: "admin-contenus-territoires",
    path: "/admin/contenus/territoires",
    meta: indexDw9yMt5XZUMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/territoires/index.vue")
  },
  {
    name: "admin-contenus-testimonials-id-edit",
    path: "/admin/contenus/testimonials/:id()/edit",
    meta: editKkOip6fG15Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/testimonials/[id]/edit.vue")
  },
  {
    name: "admin-contenus-testimonials",
    path: "/admin/contenus/testimonials",
    meta: indexERUVgJq9DWMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/contenus/testimonials/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexEWl7ATXQySMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/index.vue")
  },
  {
    name: "admin-missions-id-benevoles-informations",
    path: "/admin/missions/:id()/benevoles-informations",
    meta: benevoles_45informationsUgnEQVVK4IMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/benevoles-informations.vue")
  },
  {
    name: "admin-missions-id-benevoles",
    path: "/admin/missions/:id()/benevoles",
    meta: benevolesBwAsdfh5quMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/benevoles.vue")
  },
  {
    name: "admin-missions-id-dates",
    path: "/admin/missions/:id()/dates",
    meta: dates8WvGOX9rCmMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/dates.vue")
  },
  {
    name: "admin-missions-id",
    path: "/admin/missions/:id()",
    meta: indexZiKc9p2uY6Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/index.vue")
  },
  {
    name: "admin-missions-id-informations",
    path: "/admin/missions/:id()/informations",
    meta: informationsnV7Qh10QyIMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/informations.vue")
  },
  {
    name: "admin-missions-id-lieux",
    path: "/admin/missions/:id()/lieux",
    meta: lieuxKkCIWrQLOsMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/lieux.vue")
  },
  {
    name: "admin-missions-id-responsables",
    path: "/admin/missions/:id()/responsables",
    meta: responsablesiViv479Du9Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/responsables.vue")
  },
  {
    name: "admin-missions-id-title",
    path: "/admin/missions/:id()/title",
    meta: titleOV8ZIKvr9zMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/title.vue")
  },
  {
    name: "admin-missions-id-trouver-des-benevoles",
    path: "/admin/missions/:id()/trouver-des-benevoles",
    meta: index2QqKjiBHaJMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/trouver-des-benevoles/index.vue")
  },
  {
    name: "admin-missions-id-trouver-des-benevoles-redirect",
    path: "/admin/missions/:id()/trouver-des-benevoles/redirect",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/trouver-des-benevoles/redirect.vue")
  },
  {
    name: "admin-missions-id-visuel",
    path: "/admin/missions/:id()/visuel",
    meta: visuelisOzRlHlzpMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/[id]/visuel.vue")
  },
  {
    name: "admin-missions",
    path: "/admin/missions",
    meta: indexGIo22J4V3LMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/missions/index.vue")
  },
  {
    name: "admin-notes",
    path: "/admin/notes",
    meta: notesaR9Q1S0bmFMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/notes.vue")
  },
  {
    name: "admin-organisations-id-edit",
    path: "/admin/organisations/:id()/edit",
    meta: editDhPSYgudvyMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/organisations/[id]/edit.vue")
  },
  {
    name: "admin-organisations-id",
    path: "/admin/organisations/:id()",
    meta: indexLmGFAvL9uEMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/organisations/[id]/index.vue")
  },
  {
    name: "admin-organisations-id-missions-add",
    path: "/admin/organisations/:id()/missions/add",
    meta: addeVqQ2PRFfeMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/organisations/[id]/missions/add.vue")
  },
  {
    name: "admin-organisations",
    path: "/admin/organisations",
    meta: indexFoWR65wf0PMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/organisations/index.vue")
  },
  {
    name: "admin-other-invitations",
    path: "/admin/other/invitations",
    meta: invitations7xvzKUZH1pMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/other/invitations.vue")
  },
  {
    name: "admin-other-logs",
    path: "/admin/other/logs",
    meta: logsCS4gFFWZIoMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/other/logs.vue")
  },
  {
    name: "admin-participations",
    path: "/admin/participations",
    meta: indexuPcNW20bPuMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/participations/index.vue")
  },
  {
    name: "admin-ressources",
    path: "/admin/ressources",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/ressources.vue")
  },
  {
    name: "admin-settings-emails",
    path: "/admin/settings/emails",
    meta: emailsSmSratLmIKMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/settings/emails.vue")
  },
  {
    name: "admin-settings-messages",
    path: "/admin/settings/messages",
    meta: messagesykyFwupoCcMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/settings/messages.vue")
  },
  {
    name: "admin-settings-rules-id-edit",
    path: "/admin/settings/rules/:id()/edit",
    meta: editJJv655XPEWMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/settings/rules/[id]/edit.vue")
  },
  {
    name: "admin-settings-rules-id-pending-items",
    path: "/admin/settings/rules/:id()/pending-items",
    meta: pending_45itemsDAFsJ0BoatMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/settings/rules/[id]/pending-items.vue")
  },
  {
    name: "admin-settings-rules-add",
    path: "/admin/settings/rules/add",
    meta: addbDS3HBnOXEMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/settings/rules/add.vue")
  },
  {
    name: "admin-settings-rules",
    path: "/admin/settings/rules",
    meta: indexIzOll97JAIMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/settings/rules/index.vue")
  },
  {
    name: "admin-statistics-api-engagement-trafic-entrant",
    path: "/admin/statistics/api-engagement/trafic-entrant",
    meta: trafic_45entranthdar7huWmDMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/api-engagement/trafic-entrant.vue")
  },
  {
    name: "admin-statistics-api-engagement-trafic-sortant",
    path: "/admin/statistics/api-engagement/trafic-sortant",
    meta: trafic_45sortantGjjHgtMiCMMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/api-engagement/trafic-sortant.vue")
  },
  {
    name: "admin-statistics-conversions",
    path: "/admin/statistics/conversions",
    meta: conversionsScDY649YcJMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/conversions.vue")
  },
  {
    name: "admin-statistics-departements-missions-a-valider",
    path: "/admin/statistics/departements/missions-a-valider",
    meta: missions_45a_45validervRToswqNPdMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/departements/missions-a-valider.vue")
  },
  {
    name: "admin-statistics-departements-missions-perimees",
    path: "/admin/statistics/departements/missions-perimees",
    meta: missions_45perimeeskDSIC6w8eFMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/departements/missions-perimees.vue")
  },
  {
    name: "admin-statistics-departements-organisations-a-valider",
    path: "/admin/statistics/departements/organisations-a-valider",
    meta: organisations_45a_45validerb3txtskp7VMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/departements/organisations-a-valider.vue")
  },
  {
    name: "admin-statistics",
    path: "/admin/statistics",
    meta: indexuGrApDhxVSMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/index.vue")
  },
  {
    name: "admin-statistics-indicateurs-cles",
    path: "/admin/statistics/indicateurs-cles",
    meta: indicateurs_45clesvxKAYTOaqlMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/indicateurs-cles.vue")
  },
  {
    name: "admin-statistics-missions",
    path: "/admin/statistics/missions",
    meta: missionsQ6CtSxxikzMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/missions.vue")
  },
  {
    name: "admin-statistics-organisations",
    path: "/admin/statistics/organisations",
    meta: indexAxBOtKYUbyMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/organisations/index.vue")
  },
  {
    name: "admin-statistics-organisations-missions-perimees",
    path: "/admin/statistics/organisations/missions-perimees",
    meta: missions_45perimeesYMg0LBdldbMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/organisations/missions-perimees.vue")
  },
  {
    name: "admin-statistics-organisations-participations-a-valider",
    path: "/admin/statistics/organisations/participations-a-valider",
    meta: participations_45a_45validero6vnKhczlJMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/organisations/participations-a-valider.vue")
  },
  {
    name: "admin-statistics-organisations-participations-refusees-annulees",
    path: "/admin/statistics/organisations/participations-refusees-annulees",
    meta: participations_45refusees_45annuleesFL9iLwlyGzMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/organisations/participations-refusees-annulees.vue")
  },
  {
    name: "admin-statistics-participations",
    path: "/admin/statistics/participations",
    meta: participationsD9tu6t1xtAMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/participations.vue")
  },
  {
    name: "admin-statistics-places",
    path: "/admin/statistics/places",
    meta: placesiMNxEqYXGvMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/places.vue")
  },
  {
    name: "admin-statistics-suivis-moderations",
    path: "/admin/statistics/suivis/moderations",
    meta: moderationsu9yEBSHg05Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/suivis/moderations.vue")
  },
  {
    name: "admin-statistics-utilisateurs",
    path: "/admin/statistics/utilisateurs",
    meta: utilisateursrk79P4nPF0Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/statistics/utilisateurs.vue")
  },
  {
    name: "admin-taxonomies-slug-id-edit",
    path: "/admin/taxonomies/:slug()/:id()/edit",
    meta: editPjoELgkUueMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/taxonomies/[slug]/[id]/edit.vue")
  },
  {
    name: "admin-taxonomies-slug-add",
    path: "/admin/taxonomies/:slug()/add",
    meta: addxly1KgIDqPMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/taxonomies/[slug]/add.vue")
  },
  {
    name: "admin-taxonomies-slug",
    path: "/admin/taxonomies/:slug()",
    meta: indexYuhKsIGPyIMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/taxonomies/[slug]/index.vue")
  },
  {
    name: "admin-temoignages",
    path: "/admin/temoignages",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/temoignages.vue")
  },
  {
    name: "admin-utilisateurs-id-edit",
    path: "/admin/utilisateurs/:id()/edit",
    meta: editsbv44cRjCfMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/utilisateurs/[id]/edit.vue")
  },
  {
    name: "admin-utilisateurs-id",
    path: "/admin/utilisateurs/:id()",
    meta: indexDPAc89APNOMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/utilisateurs/[id]/index.vue")
  },
  {
    name: "admin-utilisateurs",
    path: "/admin/utilisateurs",
    meta: indexYlaSUQxk1kMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/admin/utilisateurs/index.vue")
  },
  {
    name: "associations-slug",
    path: "/associations/:slug()",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/associations/[slug].vue")
  },
  {
    name: "charte-bon-fonctionnement-benevoles",
    path: "/charte-bon-fonctionnement/benevoles",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/charte-bon-fonctionnement/benevoles.vue")
  },
  {
    name: "charte-bon-fonctionnement",
    path: "/charte-bon-fonctionnement",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/charte-bon-fonctionnement/index.vue")
  },
  {
    name: "charte-bon-fonctionnement-responsables",
    path: "/charte-bon-fonctionnement/responsables",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/charte-bon-fonctionnement/responsables.vue")
  },
  {
    name: "charte-reserve-civique",
    path: "/charte-reserve-civique",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/charte-reserve-civique.vue")
  },
  {
    name: "conditions-generales-d-utilisation",
    path: "/conditions-generales-d-utilisation",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/conditions-generales-d-utilisation.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexIMtSY72J3wMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/dashboard/index.vue")
  },
  {
    name: "departements-slug",
    path: "/departements/:slug()",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/departements/[slug].vue")
  },
  {
    name: "domaines-action-slug",
    path: "/domaines-action/:slug()",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/domaines-action/[slug].vue")
  },
  {
    name: "en-ce-moment",
    path: "/en-ce-moment",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/en-ce-moment.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/index.vue")
  },
  {
    name: "inscription-benevole",
    path: "/inscription/benevole",
    meta: indexN3mDyPsBisMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/benevole/index.vue")
  },
  {
    name: "inscription-benevole-step-competences",
    path: "/inscription/benevole/step/competences",
    meta: competences4h7wpUbIoMMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/benevole/step/competences.vue")
  },
  {
    name: "inscription-benevole-step-disponibilites",
    path: "/inscription/benevole/step/disponibilites",
    meta: disponibilites5yza8guvPYMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/benevole/step/disponibilites.vue")
  },
  {
    name: "inscription-benevole-step-preferences",
    path: "/inscription/benevole/step/preferences",
    meta: preferencesnSxXkqwWLIMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/benevole/step/preferences.vue")
  },
  {
    name: "inscription-benevole-step-profile",
    path: "/inscription/benevole/step/profile",
    meta: profileZvm4nmgwkDMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/benevole/step/profile.vue")
  },
  {
    name: "inscription",
    path: "/inscription",
    meta: indexo64d6GB4saMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/index.vue")
  },
  {
    name: "inscription-responsable",
    path: "/inscription/responsable",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/index.vue")
  },
  {
    name: "inscription-responsable-step-charte-bon-fonctionnement",
    path: "/inscription/responsable/step/charte-bon-fonctionnement",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/charte-bon-fonctionnement.vue")
  },
  {
    name: "inscription-responsable-step-collectivite-confirmation",
    path: "/inscription/responsable/step/collectivite-confirmation",
    meta: collectivite_45confirmationPYMrtAYCAWMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/collectivite-confirmation.vue")
  },
  {
    name: "inscription-responsable-step-collectivite",
    path: "/inscription/responsable/step/collectivite",
    meta: collectivitezvA8W6H3mbMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/collectivite.vue")
  },
  {
    name: "inscription-responsable-step-organisation-confirmation",
    path: "/inscription/responsable/step/organisation-confirmation",
    meta: organisation_45confirmationG6qF7xUw48Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/organisation-confirmation.vue")
  },
  {
    name: "inscription-responsable-step-organisation-details",
    path: "/inscription/responsable/step/organisation-details",
    meta: organisation_45detailsnMvv6ytqgEMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/organisation-details.vue")
  },
  {
    name: "inscription-responsable-step-organisation-images",
    path: "/inscription/responsable/step/organisation-images",
    meta: organisation_45imagesGBrbIxd3JiMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/organisation-images.vue")
  },
  {
    name: "inscription-responsable-step-organisation",
    path: "/inscription/responsable/step/organisation",
    meta: organisationCFQZ6Oo131Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/organisation.vue")
  },
  {
    name: "inscription-responsable-step-profile",
    path: "/inscription/responsable/step/profile",
    meta: profileDzk4cJ0BLHMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/inscription/responsable/step/profile.vue")
  },
  {
    name: "invitations-hash",
    path: "/invitations/:hash()",
    meta: _91hash_93EjI9harxopMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/invitations/[hash].vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginpxmnqII6DwMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/login.vue")
  },
  {
    name: "maintenance-admin",
    path: "/maintenance/admin",
    meta: admind76e858gCzMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/maintenance/admin.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: indexDjUmk8yzTVMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/maintenance/index.vue")
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/mentions-legales.vue")
  },
  {
    name: "messages-id",
    path: "/messages/:id()",
    meta: _91id_93p2VuKIojliMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/messages/[id].vue")
  },
  {
    name: "messages",
    path: "/messages",
    meta: indexfKI3T5ZIXwMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/messages/index.vue")
  },
  {
    name: "messages-modeles-add",
    path: "/messages/modeles/add",
    meta: addL3IuLY2rjXMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/messages/modeles/add.vue")
  },
  {
    name: "messages-modeles",
    path: "/messages/modeles",
    meta: indexTUwtqcWm90Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/messages/modeles/index.vue")
  },
  {
    name: "missions-benevolat-id-slug",
    path: "/missions-benevolat/:id()/:slug()",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/missions-benevolat/[id]/[slug].vue")
  },
  {
    name: "missions-benevolat-id",
    path: "/missions-benevolat/:id()",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/missions-benevolat/[id]/index.vue")
  },
  {
    name: "missions-benevolat",
    path: "/missions-benevolat",
    meta: indexAO3SgwahEoMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/missions-benevolat/index.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notificationsbBMxqIWPIJMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/notifications.vue")
  },
  {
    name: "organisations-slug",
    path: "/organisations/:slug()",
    meta: _91slug_93VxrzEIaCCoMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/organisations/[slug].vue")
  },
  {
    name: "organisations",
    path: "/organisations",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/organisations/index.vue")
  },
  {
    name: "password-reset-token",
    path: "/password-reset/:token()",
    meta: _91token_93OFZxaBgZkoMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/password-reset/[token].vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: indexPx6sP7tvyvMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/password-reset/index.vue")
  },
  {
    name: "plan-du-site",
    path: "/plan-du-site",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/plan-du-site.vue")
  },
  {
    name: "politique-de-confidentialite",
    path: "/politique-de-confidentialite",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/politique-de-confidentialite.vue")
  },
  {
    name: "profile-alertes",
    path: "/profile/alertes",
    meta: indexew5GPrkiOiMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/alertes/index.vue")
  },
  {
    name: "profile-charte-bon-fonctionnement",
    path: "/profile/charte-bon-fonctionnement",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/charte-bon-fonctionnement.vue")
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    meta: editwW4rWugUuiMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/edit.vue")
  },
  {
    name: "profile-favoris",
    path: "/profile/favoris",
    meta: favorisHicWBeMChdMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/favoris.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexNtPjwXFb4MMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/index.vue")
  },
  {
    name: "profile-missions",
    path: "/profile/missions",
    meta: missionsHb9EBPyFMsMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/missions.vue")
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: notificationsHq5f7IOU89Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/notifications.vue")
  },
  {
    name: "profile-preferences",
    path: "/profile/preferences",
    meta: preferencesZucYNaD9RcMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/preferences.vue")
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: settingsmiixXfZZG7Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/profile/settings.vue")
  },
  {
    name: "quiz-decembre-ensemble",
    path: "/quiz/decembre-ensemble",
    meta: decembre_45ensembleAT1Lt8hBOWMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/quiz/decembre-ensemble.vue")
  },
  {
    name: "quiz-generique",
    path: "/quiz/generique",
    meta: generiquedOTQ1e4fOkMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/quiz/generique.vue")
  },
  {
    name: "quiz-mgen",
    path: "/quiz/mgen",
    meta: mgeneSJV4TrLV1Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/quiz/mgen.vue")
  },
  {
    name: "quiz-printemps-pour-la-planete",
    path: "/quiz/printemps-pour-la-planete",
    meta: printemps_45pour_45la_45planeteQj7QBfQwaWMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/quiz/printemps-pour-la-planete.vue")
  },
  {
    name: "quiz-septembre-pour-apprendre",
    path: "/quiz/septembre-pour-apprendre",
    meta: septembre_45pour_45apprendrePJHN0GCtoFMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/quiz/septembre-pour-apprendre.vue")
  },
  {
    name: "reseaux-slug",
    path: "/reseaux/:slug()",
    meta: _91slug_93n15igY616PMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/reseaux/[slug].vue")
  },
  {
    name: "stats",
    path: "/stats",
    meta: indexmV4ftfla9LMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/stats/index.vue")
  },
  {
    name: "stats-missions",
    path: "/stats/missions",
    meta: missionsweQN0xpxmgMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/stats/missions.vue")
  },
  {
    name: "stats-organisations",
    path: "/stats/organisations",
    meta: organisationsHCb55s7gtnMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/stats/organisations.vue")
  },
  {
    name: "stats-participations",
    path: "/stats/participations",
    meta: participationsCpvWAfttTuMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/stats/participations.vue")
  },
  {
    name: "stats-places",
    path: "/stats/places",
    meta: placesviN1FhCocWMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/stats/places.vue")
  },
  {
    name: "stats-utilisateurs",
    path: "/stats/utilisateurs",
    meta: utilisateursvB6cXkZZdNMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/stats/utilisateurs.vue")
  },
  {
    name: "support-actions-google-api-indexing",
    path: "/support/actions/google-api-indexing",
    meta: google_45api_45indexingnPQO7ICEO3Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/actions/google-api-indexing.vue")
  },
  {
    name: "support-actions-search-archived-users",
    path: "/support/actions/search-archived-users",
    meta: search_45archived_45usersPtPLHtHvdMMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/actions/search-archived-users.vue")
  },
  {
    name: "support-actions-transfert-organisation",
    path: "/support/actions/transfert-organisation",
    meta: transfert_45organisationuiqPrdQnI4Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/actions/transfert-organisation.vue")
  },
  {
    name: "support-actions-user-reset-context-role",
    path: "/support/actions/user-reset-context-role",
    meta: user_45reset_45context_45roleCNdQIyzcd1Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/actions/user-reset-context-role.vue")
  },
  {
    name: "support-actions-user-reset-password-link",
    path: "/support/actions/user-reset-password-link",
    meta: user_45reset_45password_45linkn9zGPRJ6vAMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/actions/user-reset-password-link.vue")
  },
  {
    name: "support-contents-doublons-organisations",
    path: "/support/contents/doublons-organisations",
    meta: doublons_45organisationstqBZLfg5SOMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/contents/doublons-organisations.vue")
  },
  {
    name: "support-contents-doublons-territoires",
    path: "/support/contents/doublons-territoires",
    meta: doublons_45territoiresXSkk3urCdrMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/contents/doublons-territoires.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: indexZDvtrxE9QXMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/index.vue")
  },
  {
    name: "support-referents-activity-logs",
    path: "/support/referents/activity-logs",
    meta: activity_45logszQqpsnj6xpMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/referents/activity-logs.vue")
  },
  {
    name: "support-referents-waiting-actions",
    path: "/support/referents/waiting-actions",
    meta: waiting_45actionsKrANWlVU85Meta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/referents/waiting-actions.vue")
  },
  {
    name: "support-responsables-missions-outdated",
    path: "/support/responsables/missions-outdated",
    meta: missions_45outdatedjB1LeLDSuLMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/responsables/missions-outdated.vue")
  },
  {
    name: "support-responsables-participations-to-be-treated",
    path: "/support/responsables/participations-to-be-treated",
    meta: participations_45to_45be_45treatedrxd1Zf1sDWMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/support/responsables/participations-to-be-treated.vue")
  },
  {
    name: "temoignages-token",
    path: "/temoignages/:token()",
    meta: _91token_93lTLQVodBbXMeta || {},
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/temoignages/[token].vue")
  },
  {
    name: "territoires",
    path: "/territoires",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/territoires/index.vue")
  },
  {
    name: "villes-slug",
    path: "/villes/:slug()",
    component: () => import("/build/bebecc19-04f0-4af6-a037-1fca09232c88/pages/villes/[slug].vue")
  },
  {
    name: component_45stubBRLwxpG2n1Meta?.name,
    path: "/register/volontaire/:pathMatch(.*)",
    component: component_45stubBRLwxpG2n1
  },
  {
    name: component_45stubBRLwxpG2n1Meta?.name,
    path: "/register/responsable/:pathMatch(.*)",
    component: component_45stubBRLwxpG2n1
  },
  {
    name: component_45stubBRLwxpG2n1Meta?.name,
    path: "/user/settings",
    component: component_45stubBRLwxpG2n1
  },
  {
    name: component_45stubBRLwxpG2n1Meta?.name,
    path: "/user/:pathMatch(.*)",
    component: component_45stubBRLwxpG2n1
  },
  {
    name: component_45stubBRLwxpG2n1Meta?.name,
    path: "/dashboard/structure/:pathMatch(.*)",
    component: component_45stubBRLwxpG2n1
  },
  {
    name: component_45stubBRLwxpG2n1Meta?.name,
    path: "/dashboard/mission/:pathMatch(.*)",
    component: component_45stubBRLwxpG2n1
  },
  {
    name: component_45stubBRLwxpG2n1Meta?.name,
    path: "/dashboard/participation/:pathMatch(.*)",
    component: component_45stubBRLwxpG2n1
  },
  {
    name: component_45stubBRLwxpG2n1Meta?.name,
    path: "/dashboard/profile/:pathMatch(.*)",
    component: component_45stubBRLwxpG2n1
  },
  {
    name: component_45stubBRLwxpG2n1Meta?.name,
    path: "/dashboard/reseaux/:pathMatch(.*)",
    component: component_45stubBRLwxpG2n1
  },
  {
    name: component_45stubBRLwxpG2n1Meta?.name,
    path: "/inscription/organisation",
    component: component_45stubBRLwxpG2n1
  },
  {
    name: component_45stubBRLwxpG2n1Meta?.name,
    path: "/statistiques",
    component: component_45stubBRLwxpG2n1
  },
  {
    name: component_45stubBRLwxpG2n1Meta?.name,
    path: "/activites/accompagnement-aux-activites-sportives",
    component: component_45stubBRLwxpG2n1
  },
  {
    name: component_45stubBRLwxpG2n1Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubBRLwxpG2n1
  }
]